// Refer to https://create-react-app.dev/docs/adding-a-css-modules-stylesheet
// On how to use scss modules with React

// Import WMN Design System scss for use in this scss file
// Tilde (~) represents the root folder(src)
@import '~assets/wmnds/vars';
@import '~assets/wmnds/mixins';

td {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

th {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.icon {
  height: 50px;
  // get-color() function taken from mixins (refer to: https://designsystem.wmnetwork.co.uk/styles/colour-palettes/ for usage)
  fill: get-color(secondary);

  // $breakpoint-md var taken from vars
  @media all and (min-width: $breakpoint-md) {
    fill: get-color(primary);
  }
}

.textMetro {
  color: get-color(metro);
}

.textArea {
  width: 70%;
  height: 10rem;
  resize: none;
  border: 2px solid get-color(secondary);
}

.iconDimension {
  width: 2.5rem;
  height: 2.5rem;
}

.searchButton {
  padding: 13px !important;
  background-color: get-color(primary) !important;
  cursor: pointer !important;
  all: unset;
}

.ddLogo {
  width: 138px;
  height: 44px !important;
}
